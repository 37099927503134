import React, { useEffect, useState, useCallback } from 'react';
import { useGesture } from 'react-use-gesture';
import cx from 'classnames';
import {
  motion,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react';
import { SanityImage } from '@components/sanity/sanityImage';
import ModuleWrapper from '../moduleWrapper';
import { Link } from 'gatsby';

import Vector from '@svg/vector.svg';
import VectorBack from '@svg/vectorback.svg';

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

const cursorTextVars = {
  visible: {
    opacity: 1,
  },
  dragging: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
};

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      ` transition-all ease-in-out duration-300 mr-4 w-[45px] h-[45px] rounded-[50%] cursor-pointer touch-manipulation bg-green/30`,
      {
        'opacity-20': !enabled,
      },
    )}
    onClick={onClick}
    disabled={!enabled}
    aria-label='next button'>
    <VectorBack className='ml-[16px] w-[11px] h-[17px] svg-green' />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      `transition-all ease-in-out duration-300  w-[45px] h-[45px] rounded-[50%] cursor-pointer touch-manipulation bg-green/30 `,
      {
        'opacity-20': !enabled,
      },
    )}
    onClick={onClick}
    disabled={!enabled}
    aria-label='next button'>
    <Vector className='m-auto w-[11px] h-[17px] svg-green' />
  </button>
);

const Card = ({
  cardText,
  location,
  header,
  title,
  image,
  listingImage,
  articleImage,
  date,
  serviceRef,
  bannerImage,
  slug,
  locationRef,
  optionalLink,
  path,
}) => {
  return (
    <Link
      to={slug ? `/${path}/${slug?.current}` : optionalLink}
      target={optionalLink ? '_blank' : '_self'}>
      <div className='border-t link-overline overflow-hidden pt-5'>
        <h4 className='blockH7 mb-[10px] text-green'>
          {header ||
            location ||
            date ||
            serviceRef?.title ||
            locationRef?.locationName}
        </h4>

        <p className='text-blue blockH4 mb-10'>{cardText || title}</p>

        <div className='relative aspect-w-4 aspect-h-3 imageZoom'>
          <SanityImage
            image={image || listingImage || articleImage || bannerImage}
            className='absolute top-0 left-0 object-cover w-full h-full'
          />
        </div>
      </div>
    </Link>
  );
};

const CardCarousel = ({ config, heading, items, path }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    dragFree: true,
  });

  const [cusorActive, setCursorActive] = useState(false);
  const [isDrag, setDrag] = useState(false);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on('select', onSelect);
  }, [embla, onSelect]);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onDrag: ({ dragging }) => setDrag(dragging),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  return (
    <>
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: '80px',
              height: '80px',
              translateX: cursorXSpring,
              translateY: cursorYSpring,
              backgroundColor: 'var(--green)',
            }}
            className='fixed left-0 top-0 rounded-full z-50 pointer-events-none cursor-none flex items-center justify-center drag-cursor  text-white'
            variants={cursorVars}
            initial='hidden'
            animate={isDrag ? 'dragging' : 'visible'}
            exit='hidden'>
            <motion.span className='' variants={cursorTextVars}>
              &lt; Drag &gt;
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>

      {/* <DragCursor> */}
      <ModuleWrapper
        {...config}
        className='px-gutter  overflow-hidden relative'>
        <div className='mb-2 md:mb-24 flex justify-between items-center'>
          <h1 className='blockH3 text-blue '>{heading}</h1>
          <div className='text-center hidden md:block'>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </div>
        </div>

        <div ref={viewportRef} {...bind()}>
          <div className='flex items-stretch gap-x-10'>
            {items?.map((item, i) => (
              <div className='border-t-2 w-full md:w-1/2 xl:w-1/3 flex-shrink-0 '>
                <Card key={i} {...item} path={path} />
              </div>
            ))}
          </div>
        </div>
        <div className='text-center md:hidden mt-10'>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </ModuleWrapper>
    </>
  );
};

export default CardCarousel;
