import React, { useEffect, useState } from 'react';
import WhiteVector from '@svg/whitevector.svg';
import cx from 'classnames';
import { Link as ScrollLink } from 'react-scroll';

// anchor scroll link
const StandardButton = ({ buttonName, aLink }) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' && window.innerHeight,
  );

  useEffect(() => {
    setWindowHeight(typeof window !== 'undefined' && window.innerHeight);
  }, []);

  return (
    <ScrollLink
      to={aLink ? aLink : 'top'}
      offset={aLink ? 0 : windowHeight}
      smooth={true}
      onMouseEnter={() => setButtonHovered(true)}
      onMouseLeave={() => setButtonHovered(false)}
      className={cx(
        '  w-[fit-content] flex mt-5 cursor-pointer transition-all ease-in-out duration-700 button-transparent ',
        {
          '!bg-green/25': buttonHovered,
        },
      )}
      style={{
        WebkitBackdropFilter: 'blur(7px)',
      }}>
      <div className='blockH8 font-medium  self-center inline-block pr-4 text-green md:text-white '>
        {buttonName}
      </div>
      <div
        className={cx(
          'inline-block  p-3 rounded-full transition-all ease-in-out duration-700 bg-green/20 md:bg-white/20',
          {
            '!bg-green': buttonHovered,
          },
        )}>
        <WhiteVector className='md:hidden w-4 h-4 svg-green' />
        <WhiteVector className='hidden md:block w-4 h-4 ' />
      </div>
    </ScrollLink>
  );
};

export default StandardButton;
