import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const ImageText = loadable(() => import('./modules/imageText'));
const TextBlock = loadable(() => import('./modules/textBlock'));
const StatsBlock = loadable(() => import('./modules/statsBlock'));
const FullImage = loadable(() => import('./modules/fullImage'));
const ServiceAccordian = loadable(() => import('./modules/serviceAccordian'));
const PageBanner = loadable(() => import('./modules/pageBanner'));
const CardCarousel = loadable(() => import('./modules/cardCarousel'));
const TwoColumnText = loadable(() => import('./modules/twoColumnText'));
const StaffGrid = loadable(() => import('./modules/staffGrid'));
const TestimonialBlock = loadable(() => import('./modules/testimonialBlock'));
const CarouselText = loadable(() => import('./modules/carouselText'));
const ServiceLogo = loadable(() => import('./modules/serviceLogo'));
const VideoText = loadable(() => import('./modules/videoText'));
const FullVideo = loadable(() => import('./modules/fullVideo'));
const ServiceCurtainSlider = loadable(() =>
  import('./modules/serviceCurtainSlider'),
);

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case 'imageText':
        return <ImageText {...item} />;
      case 'textBlock':
        return <TextBlock {...item} />;
      case 'statsBlock':
        return <StatsBlock {...item} />;
      case 'serviceCurtainSlider':
        return <ServiceCurtainSlider {...item} />;
      case 'fullImage':
        return <FullImage {...item} />;
      case 'serviceAccordian':
        return <ServiceAccordian {...item} />;
      case 'pageBanner':
        return <PageBanner {...item} />;
      case 'cardCarousel':
        return <CardCarousel {...item} />;
      case 'twoColumnText':
        return <TwoColumnText {...item} />;
      case 'staffGrid':
        return <StaffGrid {...item} />;
      case 'testimonialBlock':
        return <TestimonialBlock {...item} />;
      case 'carouselText':
        return <CarouselText {...item} />;
      case 'serviceLogo':
        return <ServiceLogo {...item} />;
      case 'videoText':
        return <VideoText {...item} />;
      case 'fullVideo':
        return <FullVideo {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
